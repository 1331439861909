var _a;
import { __awaiter } from "tslib";
import settings from '@wework/ray-core/es/global/js/settings';
import Select from '@wework/ray-core/src/components/select';
import { TextField } from '@wework/ray-core/src/components/text-field/index';
import { searchNearestMarketToUser } from './home/algolia-home';
import { setupHomePageAnalytics, trackIdeaDescriptionsCTA, trackIdeaHeadingsCTA, trackIdeaImagesCTA, trackIdeaReadMoreCTA, trackIdeaSubcategoriesCTA, trackMarketSelected, trackStartSubmitCTA, trackWorkspaceTypeSelected, } from './home/analytics-home';
import ChipSelect from '../Components/chip-select';
import LeadForm, { instantiateFormUtils } from '../Components/contact/lead-form';
import Gallery from '../Components/gallery/gallery';
import { trackContentfulCTA } from '../Shared/analytics';
import { initContactFormCustomValidation } from '../Shared/data-validation';
import { setupIntersectionObserver } from '../Shared/helpers/intersection-observer';
import importjQueryAndPlugins from '../Shared/jquery-with-plugins';
import setUpPromoBanner from '../Shared/promo-banner';
import sendTimingEvents from '../Shared/timing-analytics';
import UserCookie from '../Shared/user-cookie';
import { onContentLoaded } from '../Shared/utils';
import { isDesktop, isTablet } from '../Shared/breakpoint-events';
import { initSizeVarReporter } from '../Shared/helpers/size-var-reporter';
import { yieldToMain } from '../Shared/yield-to-main';
import { updateClass } from "../Shared/helpers/dom-helpers";
const mainSliderOptions = {
    perView: 3,
    perTouch: 1,
    bound: true,
    focusAt: 0,
    startAt: 0,
    peek: {
        before: 0,
        after: 0,
    },
    breakpoints: {
        [settings.breakpointsInPixels.tablet]: {
            perView: 1,
            peek: {
                before: 0,
                after: 20,
            },
        },
        [settings.breakpointsInPixels.desktop]: {
            perView: 2,
            peek: {
                before: 0,
                after: 20,
            },
        },
    },
};
const SELECTORS = Object.freeze({
    SEARCH_CONTAINER: Object.freeze({
        CONTAINER: '#hero-filters__container',
        WORKSPACE_SELECT: `[js-chip-select][data-chip-id='workspaceTypeChipSelect']`,
        LOCATION: '#locations-dropdown-select__container',
        LOCATION_DROPDOWN_ELEMENT: '#locations-dropdown',
        BUTTON: '#hero_button',
    }),
    IDEAS_SLIDER: '.home-ideas__slider',
    LEAD_FORM: Object.freeze({
        FORM_ID: 'homeLeadForm',
        FORM: '#homeLeadForm',
        MARKET: '#contact-form-locations-dropdown-select__container',
    }),
    LINK_MORE: '.link-more',
    CONTENTFUL: Object.freeze({
        SOLUTIONS_SECTION: '.solutions__section',
        IDEAS_SECTION: '.ideas__section',
        TAKEOVER_SECTION: '.takeover-promo__section',
        PROMO_SECTION: '.promo__section',
        PROMO_BANNER: '.promo-banner',
    }),
});
let searchForm = null;
let searchFormMarketAutoSelected = false;
let formElement;
let marketSelect;
function initSearchContactAndMarket() {
    return __awaiter(this, void 0, void 0, function* () {
        searchForm = setupSearchForm();
        const contactForm = yield setupContactForm();
        setClosestMarket([searchForm.marketComponent, contactForm.marketComponent]);
    });
}
function setUpObservers() {
    const spaceManagementSection = document.querySelector('.space-management__section');
    if (spaceManagementSection) {
        const observerOptions = {
            rootMargin: '300px 0px',
        };
        const onIntersectCallback = () => {
            const videoWrapperTemplate = document.querySelector('#video-wrapper');
            if (!videoWrapperTemplate) {
                return;
            }
            const templateContentClone = videoWrapperTemplate.content.cloneNode(true);
            videoWrapperTemplate.parentElement.replaceChildren(templateContentClone);
        };
        setupIntersectionObserver(spaceManagementSection, onIntersectCallback, observerOptions);
    }
}
function setupSearchForm() {
    var _a, _b;
    const workspaceSelect = (_a = formElement === null || formElement === void 0 ? void 0 : formElement.querySelector(SCOPED_SELECTORS.WORKSPACE_SELECT)) !== null && _a !== void 0 ? _a : null;
    const workspaceChip = ChipSelect.create(workspaceSelect, [], () => {
        const selectedWorkspace = workspaceChip.getDatasetsOfSelectedItems().map((ds) => {
            var _a;
            return ({
                trackingId: ds.trackingId,
                productIds: ((_a = ds.trackingContainedProducts) === null || _a === void 0 ? void 0 : _a.split('|')) || [],
            });
        })[0];
        trackWorkspaceTypeSelected(selectedWorkspace.trackingId, selectedWorkspace.productIds);
    }, null, null, true);
    window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
            workspaceChip.reset();
        }
    });
    const marketComponent = Select.create(formElement === null || formElement === void 0 ? void 0 : formElement.querySelector(SCOPED_SELECTORS.LOCATION));
    (_b = formElement === null || formElement === void 0 ? void 0 : formElement.querySelector(SCOPED_SELECTORS.BUTTON)) === null || _b === void 0 ? void 0 : _b.addEventListener('click', (e) => {
        e.preventDefault();
        trackStartSubmitCTA();
        if (marketSelect === null || marketSelect === void 0 ? void 0 : marketSelect.selectedIndex) {
            const locationGUID = marketSelect.options[marketSelect.selectedIndex].value;
            UserCookie.setPreferredLocation(locationGUID);
        }
        window.location.href = buildSearchUrl(marketSelect, workspaceChip);
    });
    marketSelect === null || marketSelect === void 0 ? void 0 : marketSelect.addEventListener('change', () => {
        updateWorkspaceSelectOptions(marketSelect, workspaceSelect, workspaceChip);
        if (searchFormMarketAutoSelected) {
            searchFormMarketAutoSelected = false;
            return;
        }
        const marketName = marketSelect.options[marketSelect.selectedIndex].dataset.trackingName;
        const marketId = marketSelect.options[marketSelect.selectedIndex].dataset.trackingId;
        trackMarketSelected(marketName, marketId);
    });
    return { marketComponent };
}
function updateWorkspaceSelectOptions(marketSelect, workspaceSelect, workspaceChip) {
    var _a;
    const availableWorkspaceTypesForMarket = ((_a = marketSelect.options[marketSelect.selectedIndex].dataset.availableWorkspaceTypes) === null || _a === void 0 ? void 0 : _a.split('|')) || [];
    const workspaceTypeOptions = workspaceChip.getOptions();
    workspaceTypeOptions.forEach((option) => {
        var _a;
        const optionAbbreviations = ((_a = option.dataset.abbreviation) === null || _a === void 0 ? void 0 : _a.split('|')) || [];
        const shouldDisplay = availableWorkspaceTypesForMarket.some(abbr => optionAbbreviations.includes(abbr));
        option.dataset.show = shouldDisplay ? 'true' : 'false';
    });
    const hiddenOptions = workspaceSelect.querySelectorAll('[data-show="false"]');
    if (hiddenOptions.length === workspaceTypeOptions.length) {
        hiddenOptions.forEach((option) => {
            option.dataset.show = 'true';
        });
    }
    workspaceTypeOptions.forEach(option => {
        updateClass(option, 'hidden', option.getAttribute('data-show') === 'false');
    });
    workspaceChip.reset();
}
function buildSearchUrl(locationElement, workspaceSelect) {
    var _a;
    let extraUrlParams = '';
    const heroContainer = document.querySelector('[data-default-search-url]');
    const searchUrl = locationElement.selectedIndex ? locationElement.options[locationElement.selectedIndex].dataset.url
        : (heroContainer.dataset.defaultSearchUrl);
    const datasetsOfSelectedItems = workspaceSelect.getDatasetsOfSelectedItems();
    if (datasetsOfSelectedItems.length) {
        const extraParams = ((_a = workspaceSelect.getDatasetsOfSelectedItems()[0].abbreviation) === null || _a === void 0 ? void 0 : _a.split('|')) || [];
        extraUrlParams = `&product=${extraParams.join('&product=')}`;
    }
    return `${searchUrl}${extraUrlParams}`;
}
function setupIdeasSlider() {
    if (document.querySelector(SELECTORS.IDEAS_SLIDER)) {
        const gallery = new Gallery(SELECTORS.IDEAS_SLIDER, mainSliderOptions, null);
        gallery.mount();
        window.addEventListener('breakpointchanged', (event) => {
            if (isDesktop() || isTablet()) {
                if (isDesktop()) {
                    gallery.glide.disable();
                }
                else {
                    gallery.glide.enable();
                }
                gallery.glide.update({ startAt: 0 });
            }
        });
    }
}
function setupContactForm() {
    return __awaiter(this, void 0, void 0, function* () {
        yield importjQueryAndPlugins();
        const { LEAD_FORM: SCOPED_SELECTORS } = SELECTORS;
        const contactUsForm = document.querySelector(SCOPED_SELECTORS.FORM);
        const marketComponent = Select.create(contactUsForm.querySelector(SCOPED_SELECTORS.MARKET));
        const leadForm = new LeadForm(contactUsForm, SCOPED_SELECTORS.FORM_ID, { TextField, Select });
        instantiateFormUtils();
        initContactFormCustomValidation();
        window.unobtrusiveValidation.parse(contactUsForm);
        return { marketComponent };
    });
}
function setClosestMarket(marketRaySelects) {
    function setValueInSelects(value) {
        marketRaySelects.forEach((component) => {
            if (component.value()) {
                return;
            }
            if (component === searchForm.marketComponent) {
                searchFormMarketAutoSelected = true;
            }
            component.set(value);
        });
    }
    const fromCookie = UserCookie.getPreferredLocation();
    if (fromCookie) {
        setValueInSelects(fromCookie);
    }
    else {
        searchNearestMarketToUser().then((market) => {
            if (!market) {
                return;
            }
            setValueInSelects(market.id);
        });
    }
    const changeMarketEvent = new Event('change');
    marketSelect === null || marketSelect === void 0 ? void 0 : marketSelect.dispatchEvent(changeMarketEvent);
}
function trackViewAllSolutionsModule() {
    const solutionsSection = document.querySelector(SELECTORS.CONTENTFUL.SOLUTIONS_SECTION);
    if (solutionsSection != null) {
        const viewAllSolutionsCTA = solutionsSection.querySelector(SELECTORS.LINK_MORE);
        viewAllSolutionsCTA.addEventListener('click', () => {
            trackContentfulCTA(viewAllSolutionsCTA.dataset.trackingLabel);
        });
        const solutionsLearnMoreCTAs = solutionsSection.querySelectorAll('.card-btn');
        solutionsLearnMoreCTAs.forEach((learnMoreCta) => {
            learnMoreCta.addEventListener('click', () => {
                trackContentfulCTA(learnMoreCta.dataset.trackingLabel);
            });
        });
    }
}
function trackTakeoverModule() {
    const takeoverSection = document.querySelector(SELECTORS.CONTENTFUL.TAKEOVER_SECTION);
    if (takeoverSection != null) {
        const takeoverLearnMoreCTA = takeoverSection.querySelector(SELECTORS.LINK_MORE);
        takeoverLearnMoreCTA.addEventListener('click', () => {
            trackContentfulCTA(takeoverLearnMoreCTA.dataset.trackingLabel);
        });
    }
}
function trackPromoModule() {
    const promoSection = document.querySelector(SELECTORS.CONTENTFUL.PROMO_SECTION);
    if (promoSection != null) {
        const promoLearnMoreCTA = promoSection.querySelector(SELECTORS.LINK_MORE);
        promoLearnMoreCTA.addEventListener('click', () => {
            trackContentfulCTA(promoLearnMoreCTA.dataset.trackingLabel);
        });
    }
}
function trackIdeaCards() {
    const ideasSection = document.querySelector(SELECTORS.CONTENTFUL.IDEAS_SECTION);
    if (ideasSection != null) {
        const ideasCardsImages = ideasSection.querySelectorAll('.article-card__image');
        ideasCardsImages.forEach((ideaCardImage) => {
            ideaCardImage.addEventListener('click', () => {
                trackIdeaImagesCTA();
            });
        });
        const ideasCardsSubcategories = ideasSection.querySelectorAll('.article-card__category');
        ideasCardsSubcategories.forEach((ideaCardSubcategory) => {
            ideaCardSubcategory.addEventListener('click', () => {
                trackIdeaSubcategoriesCTA();
            });
        });
        const ideasCardsTitles = ideasSection.querySelectorAll('.article-card__title--main, .article-card__title');
        ideasCardsTitles.forEach((ideaCardTitle) => {
            ideaCardTitle.addEventListener('click', () => {
                trackIdeaHeadingsCTA();
            });
        });
        const ideasCardsDescriptions = ideasSection.querySelectorAll('.article-card__description');
        ideasCardsDescriptions.forEach((ideaCardDescription) => {
            ideaCardDescription.addEventListener('click', () => {
                trackIdeaDescriptionsCTA();
            });
        });
        const ideaCardReadMoreCta = ideasSection.querySelector(SELECTORS.LINK_MORE);
        ideaCardReadMoreCta.addEventListener('click', () => {
            trackIdeaReadMoreCTA();
        });
    }
}
function runPageTasks() {
    return __awaiter(this, void 0, void 0, function* () {
        const tasks = [
            setUpPromoBanner,
            initSearchContactAndMarket,
            setupIdeasSlider,
            setUpObservers,
            setupHomePageAnalytics,
            trackPromoModule,
            trackViewAllSolutionsModule,
            trackTakeoverModule,
            trackIdeaCards,
            initSizeVarReporter,
        ];
        for (const task of tasks) {
            task();
            yield yieldToMain();
        }
    });
}
onContentLoaded(runPageTasks);
const { SEARCH_CONTAINER: SCOPED_SELECTORS } = SELECTORS;
formElement = document.querySelector(SCOPED_SELECTORS.CONTAINER);
marketSelect = (_a = formElement === null || formElement === void 0 ? void 0 : formElement.querySelector(SCOPED_SELECTORS.LOCATION_DROPDOWN_ELEMENT)) !== null && _a !== void 0 ? _a : null;
window.addEventListener('load', () => setTimeout(sendTimingEvents));
